import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box 
      component="footer" 
      textAlign="center" 
      sx={{ borderTop: '1px solid #ccc', padding: '1rem 0', marginTop: '2rem' }}
    >
      <Typography variant="body2">
        &copy; 2024 PDFSage. All rights reserved. <a href="mailto:bo@pdfsage.net">bo@pdfsage.net</a>
      </Typography>
      <MuiLink component={Link} to="/privacy-policy" sx={{ margin: '0 1rem' }}>
        Privacy Policy
      </MuiLink>
      <MuiLink component={Link} to="/termsofservice">
        Terms of Service
      </MuiLink>
    </Box>
  );
}

export default Footer;
