import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Example translation resources (add your actual translations here)
const resources = {
  en: {
    translation: {
      "app_title": "PDFSage",
      "hero_title": "Secure AI-Powered PDF Editing & Knowledge Hub",
      "hero_description": "Seamlessly edit, scan, and manage PDFs on iOS, Android, Web, and soon HarmonyOS...",
      "learn_vision": "Learn About Our Vision",
      // Add more translations...
    }
  }
  // Add other languages here
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', 
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
