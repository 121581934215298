import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem', fontFamily: 'sans-serif' }}>
      <h1>Privacy Policy</h1>
      <p>
        At PDFSage, we understand that the most important assets people, organizations, and governments possess are their
        documents, techniques, and intellectual property. Your trust is paramount, and we are committed to ensuring that
        the data you store and process within our platform—whether it’s source code, sensitive documents, or proprietary
        knowledge—is handled with the utmost care and confidentiality.
      </p>
      <h2>Data Collection & Usage</h2>
      <p>
        We collect user documents and related metadata to deliver our AI-driven PDF editing and knowledge retrieval functionalities.
        Your PDFs and scanned documents are processed by embedding models to create high-dimensional vector representations
        that enable semantic search and contextual understanding. These embeddings help our integrated LLM (Gemini 2.0 Pro)
        provide accurate and context-rich responses without requiring you to continuously refeed information.
      </p>
      <p>
        Personally identifiable information (PII) is limited to what’s needed for authentication (via SSO) and account management.
        We never sell your personal data or documents to third parties, and we do not use your content for advertising purposes.
      </p>
      <h2>Data Security & Storage</h2>
      <p>
        All documents and embeddings are encrypted at rest and in transit. Our storage solutions, powered by Firebase, ensure
        that data is safeguarded against unauthorized access. Strict access controls and security measures prevent any third-party
        from viewing your sensitive information.
      </p>
      <h2>Third-Party Services</h2>
      <p>
        We rely on industry-leading infrastructure and identity providers. Any third-party integrations we use adhere to equally
        high privacy and security standards. We only partner with services that align with our mission of providing a secure,
        cryptographically protected AI knowledge hub.
      </p>
      <h2>Your Rights</h2>
      <p>
        You retain all rights to your documents and the intellectual property contained within them. At any time, you may
        request the deletion of your data from our servers. Our customer support team can guide you through this process.
      </p>
      <h2>Policy Updates</h2>
      <p>
        As our platform evolves and new technologies emerge, we may update this policy to reflect improvements in data handling
        and security practices. We will notify you of any significant changes and encourage you to review the policy periodically.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
