import React from 'react';
import { AppBar, Toolbar, Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-scroll';
import PDFSageLogo from './PDFSage.png'; // Adjust path
import { useInView } from 'react-intersection-observer';

const sections = [
  { name: 'Features', id: 'features' },
  { name: 'How It Works', id: 'how-it-works' },
  { name: 'Mobile Apps', id: 'mobile-apps' },
  { name: 'Technology & Security', id: 'technology-security' },
  { name: 'About', id: 'about' },
];

function NavigationBar({ activeSection }) {
  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={PDFSageLogo} alt="PDFSage Logo" style={{ width: '40px', marginRight: '1rem' }} />
          <MuiLink 
            href="/" 
            color="inherit" 
            variant="h6" 
            underline="none" 
            sx={{ fontWeight: 'bold' }}
          >
            PDFSage
          </MuiLink>
        </Box>
        <nav>
          {sections.map((section) => (
            <MuiLink
              key={section.id}
              component={Link}
              to={section.id}
              smooth={true}
              spy={true}
              offset={-70}
              duration={500}
              color={activeSection === section.id ? 'secondary.main' : 'inherit'}
              sx={{ marginRight: '1rem', cursor: 'pointer' }}
            >
              {section.name}
            </MuiLink>
          ))}
        </nav>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
