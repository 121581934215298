import React from 'react';
import { Typography, Card, CardContent, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';

function OtherAppsSection() {
  return (
    <Container 
      id="our-other-apps"
      sx={{ marginBottom: '2rem' }}
      component={motion.div}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.6 }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Our Other Apps
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h5">Mobile-Device-Tools</Typography>
          <Typography variant="body1" paragraph>
            Mobile-Device-Tools is planned to become the most advanced 
            penetration testing iOS app ever created—delivering capabilities 
            that rival the versatility and depth of Kali Linux. Our vision 
            is for a fully-featured, user-friendly suite of tools designed 
            for professional penetration testers, security researchers, 
            and power users who demand fine-grained control over their 
            mobile devices. From detailed device analytics and memory 
            forensics, to powerful network scanning tools, vulnerability 
            assessments, and automated exploitation frameworks, 
            Mobile-Device-Tools will provide unparalleled insight and 
            capabilities on the go. Stay tuned as we continue building 
            this groundbreaking platform that aims to redefine what 
            is possible on iOS.
          </Typography>
          <Button 
            variant="outlined" 
            color="primary" 
            href="https://apps.apple.com/us/app/mobile-device-tools/id6738795431"
            rel="noopener noreferrer"
            target="_blank"
          >
            iOS Mobile-Device-Tools
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}

export default OtherAppsSection;
