import React from 'react';
import { Typography, Grid, Card, CardContent, Container } from '@mui/material';
import { motion } from 'framer-motion';

const features = [
  { 
    title: 'A Human-Centric Knowledge Hub', 
    description: 'Designed for individuals, organizations, and governments who need to manage, protect, and derive value from their most critical asset: their documents. Unlike bulky suites that bundle in unnecessary extras, we focus on what truly matters: secure, intelligent document handling.'
  },
  { 
    title: 'Cost-Effective Alternative to Adobe Acrobat + Scan', 
    description: 'Industry-leading OCR-based PDF editing and scanning without the overhead of expensive bundles. Our subscription model is simple and tailored to your needs—pay for the maximum number of documents you store and use, with optional add-ons for natural language interactions.'
  },
  { 
    title: 'Cryptographically Secure Document Management', 
    description: 'We prioritize security and confidentiality. State-of-the-art cryptographic measures ensure that your source code, techniques, and secrets remain safe, while controlled access and rigorous authentication safeguard your intellectual property.'
  },
  { 
    title: 'Intelligent Embeddings Database & Vector Search', 
    description: 'All documents are automatically broken down into vectorized knowledge chunks. When you ask a question, we perform a similarity search across these vectors, ensuring that the system retrieves the most relevant context quickly and accurately.'
  },
  { 
    title: 'Context-Aware Natural Language Queries', 
    description: 'Use typed or voice-based natural language queries to navigate and discover insights hidden within your documents. No need to repeatedly feed the system background information—our embeddings and search workflows handle context seamlessly.'
  },
  { 
    title: 'Gemini 1.5 Pro Integration', 
    description: 'Our platform partners with Gemini 1.5 Pro to deliver highly contextual, responsive answers. This advanced AI model leverages embedded knowledge to provide accurate, nuanced responses, streamlining your workflow without repetitive data input.'
  },
  { 
    title: 'Cross-Platform Excellence: iOS, Android, Web, HarmonyOS', 
    description: 'Start with iOS and expand to Android, web, and Huawei HarmonyOS. Enjoy consistent PDF editing, scanning, and knowledge retrieval across devices, all synced through Firebase for seamless Single Sign-On and unified document management.'
  },
  { 
    title: 'Firebase, Firestore, Vertex AI, and SSO Integration', 
    description: 'Leverage Firebase for secure storage, Firestore for structured data, and Vertex AI for powerful ML-driven features. Single Sign-On ensures a unified, frictionless experience, so you can access and manage your documents from anywhere.'
  }
];

function FeaturesSection() {
  return (
    <Container 
      id="features" 
      sx={{ marginBottom: '2rem' }}
      component={motion.div}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.6 }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Core Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body1">{feature.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default FeaturesSection;
