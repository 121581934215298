import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function HeroSection() {
  const { t } = useTranslation();
  
  return (
    <Container 
      id="hero"
      sx={{ textAlign: 'center', marginTop: { xs: '4rem', md: '6rem' }, marginBottom: '2rem' }} 
      component={motion.div}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.6 }}
    >
      <Typography variant="h3" gutterBottom>
        {t('hero_title')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('hero_description')}
      </Typography>
    </Container>
  );
}

export default HeroSection;
