import React, { useState, useEffect, Suspense, lazy } from 'react';
import NavigationBar from '../components/NavigationBar';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import MobileAppsSection from '../components/MobileAppsSection';
import AboutSection from '../components/AboutSection';
import OtherAppsSection from '../components/OtherAppsSection';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Container } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const HowItWorksSection = lazy(() => import('../components/HowItWorksSection'));
const TechnologySecuritySection = lazy(() => import('../components/TechnologySecuritySection'));

function Home() {
  const [activeSection, setActiveSection] = useState('');

  // Define your sections
  const sections = [
    'hero',
    'mission-statement',
    'technology-security',
    'features',
    'how-it-works',
    'mobile-apps',
    'about',
    'our-other-apps',
  ];

  const options = {
    threshold: 0.25,
  };

  // Call useInView for each section individually
  const heroRef = useInView(options);
  const missionRef = useInView(options);
  const techSecRef = useInView(options);
  const featuresRef = useInView(options);
  const howItWorksRef = useInView(options);
  const mobileAppsRef = useInView(options);
  const aboutRef = useInView(options);
  const otherAppsRef = useInView(options);

  // Bundle them for easier iteration
  const sectionRefs = [
    heroRef,
    missionRef,
    techSecRef,
    featuresRef,
    howItWorksRef,
    mobileAppsRef,
    aboutRef,
    otherAppsRef,
  ];

  useEffect(() => {
    sectionRefs.forEach(({ inView, entry }) => {
      if (inView && entry) {
        const currentId = entry.target.id;
        setActiveSection(currentId);
      }
    });
  }, [sectionRefs]);

  return (
    <>
      <Helmet>
        <title>PDFSage - A Secure AI Knowledge Hub for Productive Humans</title>
        <meta 
          name="description" 
          content="PDFSage is a cryptographically secure, AI-powered PDF editing and management tool designed to empower productive people and organizations. It integrates industry-leading PDF capabilities, embeddings, and contextual AI (Gemini 1.5 Pro) to streamline document workflows and knowledge retrieval, at a more accessible cost." 
        />
      </Helmet>
      <NavigationBar activeSection={activeSection} />
      <Box sx={{ marginTop: '4rem' }}>
        {/* Hero Section */}
        <Box id="hero" ref={heroRef.ref}>
          <HeroSection />
        </Box>

        {/* Mission Statement Section (New) */}
        <Box id="mission-statement" ref={missionRef.ref} sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
          <Container maxWidth="md">
            <Typography variant="h4" component="h2" gutterBottom>
              Our Vision: A Beneficial, Secure AI Knowledge Hub
            </Typography>
            <Typography variant="body1" paragraph>
              In a world where organizations, governments, and individuals rely heavily on their documents—
              whether source code, proprietary techniques, research papers, or confidential records—our mission 
              is to provide a cryptographically secure, AI-powered knowledge hub. We believe that the most 
              important asset that productive people and entities hold is their documentation. 
            </Typography>
            <Typography variant="body1" paragraph>
              While Adobe Acrobat + Scan currently leads the market in features like OCR-based PDF editing, 
              many users find their subscription model expensive and bloated with tools they don't need. Our 
              goal is to offer a more cost-effective, focused solution that delivers top-tier PDF capabilities, 
              AI-driven document analysis, and secure storage of crucial information, without bundling unrelated 
              services.
            </Typography>
            <Typography variant="body1" paragraph>
              We're introducing a subscription model based on the maximum number of documents stored and usage 
              of natural language interfaces. This flexible approach ensures users pay only for what they need. 
              Our platform, launching initially on iOS, Android, web, and HarmonyOS, integrates with Firebase for 
              secure SSO login and seamless syncing. PDFSage automatically embeds documents into a vector 
              database using advanced embedding models, enabling lightning-fast semantic search.
            </Typography>
            <Typography variant="body1" paragraph>
              When a user queries the system—either by typing or voice—their query is converted into embeddings 
              and similarity-searched against the stored vectors. The Gemini 1.5 Pro AI model then generates a 
              contextually rich response without requiring users to repeatedly supply their own knowledge base 
              content. This approach ensures a deeply integrated, intelligent, and streamlined user experience, 
              setting us apart from conventional LLM-based tools like ChatGPT, where users often forget to 
              provide context.
            </Typography>
            <Typography variant="body1">
              Join us in redefining how you manage and leverage your most important digital assets. By securely 
              integrating AI, we aim to enhance productivity, knowledge discovery, and decision-making, all at 
              a more accessible price point.
            </Typography>
          </Container>
        </Box>

        <Suspense fallback={<div>Loading Technology &amp; Security Solutions...</div>}>
          <Box id="technology-security" ref={techSecRef.ref}>
            <TechnologySecuritySection />
          </Box>
        </Suspense>

        <Box id="features" ref={featuresRef.ref}>
          <FeaturesSection />
        </Box>

        <Suspense fallback={<div>Loading How It Works Overview...</div>}>
          <Box id="how-it-works" ref={howItWorksRef.ref}>
            <HowItWorksSection />
          </Box>
        </Suspense>

        <Box id="mobile-apps" ref={mobileAppsRef.ref}>
          <MobileAppsSection />
        </Box>

        <Box id="about" ref={aboutRef.ref}>
          <AboutSection />
        </Box>

        <Box id="our-other-apps" ref={otherAppsRef.ref}>
          <OtherAppsSection />
        </Box>

        <Footer />
      </Box>
    </>
  );
}

export default Home;
