import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

// Placeholder pages
function AboutPage() { return <div>About Page</div>; }
function TechnologyPage() { return <div>Technology Details</div>; }
function SecurityPage() { return <div>Security Details</div>; }
function PrivacyPolicyPage() { return PrivacyPolicy; }
function TermsOfServicePage() { return TermsOfService; }

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/technology" element={<TechnologyPage />} />
          <Route path="/security" element={<SecurityPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/termsofservice" element={<TermsOfServicePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
