import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem', fontFamily: 'sans-serif' }}>
      <h1>Terms of Service</h1>
      <p>
        By using PDFSage, you agree to the following terms and conditions. We built this platform to provide a secure, AI-driven
        knowledge hub centered around PDF editing, scanning, and semantic search. Our aim is to benefit productive individuals,
        organizations, and governments by protecting and enhancing their most valuable asset: their documents.
      </p>
      <h2>Use of the Service</h2>
      <p>
        You must use PDFSage in compliance with all applicable laws and regulations. You may not upload or store content that
        violates intellectual property rights, contains malicious code, or is otherwise unlawful. We reserve the right to
        suspend or terminate accounts that engage in fraudulent, abusive, or harmful activities.
      </p>
      <h2>Intellectual Property</h2>
      <p>
        All original materials, including code, text, graphics, and other content created by PDFSage, are owned by us or our
        licensors. Your documents and intellectual property remain yours. We do not claim ownership over the content you
        upload and process. We only use this data to provide and improve the services described in our Privacy Policy.
      </p>
      <h2>Subscription & Fees</h2>
      <p>
        PDFSage operates on a subscription model. Pricing is based on factors such as the maximum number of documents stored
        and usage of natural language queries (typed or voice). We aim to provide a cost-effective alternative to solutions
        like Adobe Acrobat + Scan. You agree to pay all applicable fees associated with your subscription tier. Failure to
        pay may result in service suspension.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        While we strive to provide a secure and reliable platform, PDFSage is offered “as is” without warranties of any kind.
        We are not liable for any losses, damages, or disruptions arising from service downtime, data corruption, or unauthorized
        access, to the fullest extent permitted by law.
      </p>
      <h2>Modifications</h2>
      <p>
        We reserve the right to modify these Terms of Service at any time. We will notify you of any significant changes and
        your continued use of the platform after such changes indicates your acceptance of the new terms.
      </p>
    </div>
  );
};

export default TermsOfService;
