import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';

function MobileAppsSection() {
  return (
    <Container 
      id="mobile-apps"
      sx={{ textAlign: 'center', marginBottom: '2rem' }}
      component={motion.div}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.6 }}
    >
      <Typography variant="h4" gutterBottom>
        Mobile Apps & Platform Support
      </Typography>
      <Typography variant="body1" paragraph>
        Enjoy PDFSage on the go...
      </Typography>
      <div style={{ marginBottom: '1rem' }}>
        <Button
          variant="outlined"
          color="primary"
          href="https://play.google.com"
          rel="noopener noreferrer"
          target="_blank"
          sx={{ marginRight: '1rem' }}
        >
          Download for Android
        </Button>
        <Button 
          variant="outlined" 
          color="primary" 
          href="https://apps.apple.com" 
          rel="noopener noreferrer"
          target="_blank"
        >
          Download for iOS
        </Button>
      </div>
      <Typography variant="body1">
        Or use our Angular-based web PDF tool right from your browser—no installation required.
      </Typography>
    </Container>
  );
}

export default MobileAppsSection;
