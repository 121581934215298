import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function AboutSection() {
  return (
    <Container
      id="about"
      sx={{ textAlign: 'center', marginBottom: '2rem' }}
      component={motion.div}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ duration: 0.6 }}
    >
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" paragraph>
        I wanted to build a tool that is truly beneficial to humanity—especially those who engage in productive endeavors that drive society forward. In today’s world, the most important assets that productive individuals, organizations, and governments possess are their documents, which may include source code, techniques, and even closely guarded secrets. Recognizing this, I set out to create a cryptographically as-secure-as-possible AI knowledge hub. Our starting point lies in delivering industry-leading PDF solutions accessible on iOS, Android, the web, and HarmonyOS.
      </Typography>
      <Typography variant="body1" paragraph>
        Currently, Adobe Acrobat + Scan stands out as one of the few (if not the only) companies offering "OCR edit" capabilities. Yet, many users find their pricing out of reach. Based on conversations with nearly half of the people I approached about this product, there is a growing demand for a more affordable alternative that matches or surpasses Adobe’s quality. Adobe tends to bundle Acrobat + Scan with its Adobe Cloud suite, which includes tools like Photoshop—applications not everyone needs. This indicates a clear market gap we aim to address.
      </Typography>
      <Typography variant="body1" paragraph>
        Our model involves a subscription based on the maximum number of documents stored, supplemented by additional fees for heavy users of natural language input (typed or voice). We are starting with a feature-rich iOS, Android, web, and HarmonyOS application for PDF editing and scanning, all integrated seamlessly through SSO login and synced via Firebase. Under the hood, the system continuously embeds knowledge chunks into a vector database using advanced embedding models.
      </Typography>
      <Typography variant="body1" paragraph>
        When a user queries the system in natural language, their query is processed by the same embeddings model, performing a similarity search to find the most relevant stored knowledge. We then leverage Gemini 1.5 Pro in contextual response generation mode. This empowers the system to understand and utilize the user’s existing knowledge context automatically, without requiring them to re-feed the same details repeatedly.
      </Typography>
      <Typography variant="body1" paragraph>
        This approach offers a huge advantage over, for example, ChatGPT, where users might forget to provide necessary context in each interaction, leading to suboptimal performance. Our iOS SDK is ready, and while the Android SDK version is still catching up, we have a strong foundation. We are also gearing up for Huawei’s HarmonyOS (Android 12) SDK and developing an Angular-based web PDF tool. On the backend, we rely on Firebase for storage, Firestore, SSO, and more, as well as Vertex AI for advanced model integration.
      </Typography>
    </Container>
  );
}

export default AboutSection;
